import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Logo = forwardRef(({ disabledLink = false, style }, ref) => {
  const logo = (
    <div ref={ref} style={{ width: 30, height: 42, cursor: 'pointer', ...style }}>
      <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 32V22.6667H6.98451C7.27554 22.6667 7.54577 22.7444 7.79522 22.9C8.04466 23.0469 8.24006 23.25 8.38142 23.5093C8.53109 23.7599 8.60592 24.0407 8.60592 24.3519V26.2574C8.60592 26.3698 8.59345 26.4821 8.5685 26.5944C8.55187 26.7068 8.51861 26.8105 8.46872 26.9056C8.63502 27.1475 8.75975 27.3765 8.84289 27.5926C8.93436 27.8 8.98009 28.0117 8.98009 28.2278V30.3148C8.98009 30.6259 8.90526 30.9111 8.75559 31.1704C8.61423 31.421 8.41883 31.6241 8.16939 31.7796C7.91994 31.9265 7.64555 32 7.34621 32H0ZM1.65882 30.6H7.32127C7.40442 30.6 7.47509 30.5698 7.5333 30.5093C7.5915 30.4401 7.6206 30.3623 7.6206 30.2759V28.2667C7.6206 28.1802 7.5915 28.1068 7.5333 28.0463C7.47509 27.9772 7.40442 27.9426 7.32127 27.9426H1.65882C1.57567 27.9426 1.50084 27.9772 1.43432 28.0463C1.37612 28.1068 1.34701 28.1802 1.34701 28.2667V30.2759C1.34701 30.3623 1.37612 30.4401 1.43432 30.5093C1.50084 30.5698 1.57567 30.6 1.65882 30.6ZM1.65882 26.5426H6.9471C7.03025 26.5426 7.10092 26.5123 7.15913 26.4519C7.21733 26.3827 7.24643 26.3049 7.24643 26.2185V24.3907C7.24643 24.3043 7.21733 24.2309 7.15913 24.1704C7.10092 24.1012 7.03025 24.0667 6.9471 24.0667H1.65882C1.57567 24.0667 1.50084 24.1012 1.43432 24.1704C1.37612 24.2309 1.34701 24.3043 1.34701 24.3907V26.2185C1.34701 26.3049 1.37612 26.3827 1.43432 26.4519C1.50084 26.5123 1.57567 26.5426 1.65882 26.5426Z"
          fill="white"
        />
        <path
          d="M11.9115 32C11.6205 32 11.3502 31.9265 11.1008 31.7796C10.8513 31.6241 10.6518 31.421 10.5021 31.1704C10.3608 30.9111 10.2901 30.6259 10.2901 30.3148V29.7704H11.6371V30.2759C11.6371 30.3623 11.6662 30.4401 11.7244 30.5093C11.7909 30.5698 11.8658 30.6 11.9489 30.6H17.6113C17.6945 30.6 17.7652 30.5698 17.8234 30.5093C17.8816 30.4401 17.9107 30.3623 17.9107 30.2759V28.3574C17.9107 28.2623 17.8816 28.1846 17.8234 28.1241C17.7652 28.0636 17.6945 28.0333 17.6113 28.0333H11.9115C11.6205 28.0333 11.3502 27.9599 11.1008 27.813C10.8513 27.6574 10.6518 27.4543 10.5021 27.2037C10.3608 26.9444 10.2901 26.6593 10.2901 26.3481V24.3519C10.2901 24.0407 10.3608 23.7599 10.5021 23.5093C10.6518 23.25 10.8513 23.0469 11.1008 22.9C11.3502 22.7444 11.6205 22.6667 11.9115 22.6667H17.6488C17.9398 22.6667 18.21 22.7444 18.4595 22.9C18.7089 23.0469 18.9043 23.25 19.0457 23.5093C19.1953 23.7599 19.2702 24.0407 19.2702 24.3519V24.8963H17.9107V24.3907C17.9107 24.3043 17.8816 24.2309 17.8234 24.1704C17.7652 24.1012 17.6945 24.0667 17.6113 24.0667H11.9489C11.8658 24.0667 11.7909 24.1012 11.7244 24.1704C11.6662 24.2309 11.6371 24.3043 11.6371 24.3907V26.3093C11.6371 26.4043 11.6662 26.4821 11.7244 26.5426C11.7909 26.6031 11.8658 26.6333 11.9489 26.6333H17.6488C17.9398 26.6333 18.21 26.7111 18.4595 26.8667C18.7089 27.0136 18.9043 27.2167 19.0457 27.4759C19.1953 27.7265 19.2702 28.0074 19.2702 28.3185V30.3148C19.2702 30.6259 19.1953 30.9111 19.0457 31.1704C18.9043 31.421 18.7089 31.6241 18.4595 31.7796C18.21 31.9265 17.9398 32 17.6488 32H11.9115Z"
          fill="white"
        />
        <path
          d="M22.2413 32C21.942 32 21.6676 31.9265 21.4181 31.7796C21.177 31.6241 20.9816 31.421 20.8319 31.1704C20.6906 30.9111 20.6199 30.6259 20.6199 30.3148V24.3519C20.6199 24.0407 20.6906 23.7599 20.8319 23.5093C20.9816 23.25 21.177 23.0469 21.4181 22.9C21.6676 22.7444 21.942 22.6667 22.2413 22.6667H27.9661C28.2655 22.6667 28.5399 22.7444 28.7893 22.9C29.0387 23.0469 29.2341 23.25 29.3755 23.5093C29.5252 23.7599 29.6 24.0407 29.6 24.3519V24.9093H28.2405V24.3907C28.2405 24.3043 28.2114 24.2309 28.1532 24.1704C28.095 24.1012 28.0243 24.0667 27.9412 24.0667H22.2787C22.1956 24.0667 22.1208 24.1012 22.0542 24.1704C21.996 24.2309 21.9669 24.3043 21.9669 24.3907V30.2759C21.9669 30.3623 21.996 30.4401 22.0542 30.5093C22.1208 30.5698 22.1956 30.6 22.2787 30.6H27.9412C28.0243 30.6 28.095 30.5698 28.1532 30.5093C28.2114 30.4401 28.2405 30.3623 28.2405 30.2759V28.2926H26.1202V26.8796H29.6V30.3148C29.6 30.6259 29.5252 30.9111 29.3755 31.1704C29.2341 31.421 29.0387 31.6241 28.7893 31.7796C28.5399 31.9265 28.2655 32 27.9661 32H22.2413Z"
          fill="white"
        />
        <path
          d="M11.1822 16L6.61375 20H23.68C26.9495 20 29.6 17.3137 29.6 14V0H5.92C2.65047 0 0 2.68629 0 6V20H6.57778V16H3.94667V6.66667C3.94667 5.19391 5.12466 4 6.57778 4H25.6533V13.3333C25.6533 14.8061 24.4753 16 23.0222 16H11.1822Z"
          fill="url(#paint0_linear_11_4468)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11_4468"
            x1="20.276"
            y1="3.88176e-07"
            x2="1.65616"
            y2="21.4384"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#03A9F4" />
            <stop offset="1" stopColor="#48AB17" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <Link to="/">{logo}</Link>;
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  style: PropTypes.object,
};

export default Logo;
