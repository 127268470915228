import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ConfigProvider } from 'antd';

import useSettings from '../hooks/useSettings';
import ComponentsOverrides from './overrides';
import palette from './palette';
import StyledProvider from './styled-provider';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const { themeMode } = useSettings();

  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      token: isLight ? palette.light : palette.dark,
    }),
    [isLight]
  );

  themeOptions.components = ComponentsOverrides(themeOptions.token);

  return (
    <ConfigProvider theme={themeOptions}>
      <StyledProvider>{children}</StyledProvider>
    </ConfigProvider>
  );
}
