import Http from '../../../services/Http';

class Numbering {
  static async request(path, method, auth, data, config) {
    if (auth) {
      Http.setAuthHeader();
    } else {
      Http.removeAuthHeader();
    }

    const response = await Http.instance[method](`/numbering${path}`, data || config);
    return response.data;
  }

  static async formDataRequest(path, method, auth, data, config) {
    if (auth) {
      Http.setAuthHeader();
    } else {
      Http.removeAuthHeader();
    }

    Http.setFormDataHeader();

    const response = await Http.instance[method](`/numbering${path}`, data || config);
    return response.data;
  }

  static async downloadRequest(path, method, params, exportName) {
    const searchParams = new URLSearchParams(params);

    const request = new XMLHttpRequest();

    request.open(method, `https://api.numbering-tool.bsg.world/numbering${path}?${searchParams.toString()}`, true);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.responseType = 'blob';

    // eslint-disable-next-line func-names
    request.onload = function () {
      if (this.status === 200) {
        const blob = this.response;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, exportName);
        } else {
          const downloadLink = window.document.createElement('a');
          const contentTypeHeader = request.getResponseHeader('Content-Type');
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: contentTypeHeader,
            })
          );
          downloadLink.download = exportName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    };

    request.send(null);
  }

  // eslint-disable-next-line class-methods-use-this
  uploadFile(file, clientId, type) {
    const uploadFileFormData = new FormData();
    uploadFileFormData.append('file', file);
    uploadFileFormData.append('type', type);
    uploadFileFormData.append('clientId', clientId);

    return Numbering.formDataRequest('/upload-file', 'post', true, uploadFileFormData);
  }

  // eslint-disable-next-line class-methods-use-this
  exportNumbering(clientId) {
    const exportName = `prefixes.xlsx`;

    return Numbering.downloadRequest('/export-numbering', 'get', { clientId }, exportName);
  }

  // eslint-disable-next-line class-methods-use-this
  getNumberingCountry(clientId) {
    return Numbering.request('/get-numbering-country', 'get', false, { params: { clientId } });
  }

  // eslint-disable-next-line class-methods-use-this
  getCountryCodes() {
    return Numbering.request('/get-country-codes', 'get', false);
  }
}

export default new Numbering();
