import Http from '../../../services/Http';

class Conflicts {
  static async request(path, method, auth, data, config) {
    if (auth) {
      Http.setAuthHeader();
    } else {
      Http.removeAuthHeader();
    }

    Http.setJsonHeader();

    const response = await Http.instance[method](`/conflicts${path}`, data || config);
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  getConflicts({ clientId, page, perPage }) {
    return Conflicts.request('/get-conflicts', 'get', true, { params: { clientId, page, perPage } });
  }

  // eslint-disable-next-line class-methods-use-this
  resolveConflict({ clientId, conflictId, override }) {
    return Conflicts.request('/resolve-conflict', 'post', true, { clientId, resolution: { conflictId, override } });
  }

  // eslint-disable-next-line class-methods-use-this
  removeConflict({ clientId, conflictId }) {
    return Conflicts.request('/remove-conflict', 'post', true, { clientId, conflictId });
  }

  // eslint-disable-next-line class-methods-use-this
  bulkRemoveConflicts({ clientId, conflictsIds }) {
    return Conflicts.request('/bulk-remove-conflicts', 'post', true, { clientId, conflictsIds });
  }
}

export default new Conflicts();
