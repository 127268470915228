import React from 'react';

import { Outlet } from 'react-router';

import styled from 'styled-components';

import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';

import FileIcon from '../../assets/icon_file';
import useLocales from '../../hooks/useLocales';
import SetupIcon from '../../assets/icon_setup';
import AppHeader from '../components/AppHeader';
import NavigationHeader from '../components/NavigationHeader';

import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const MainStyle = styled(Content)(() => ({
  height: 'fit-content',
  padding: '32px 24px',
}));

// ----------------------------------------------------------------------

export default function AppLayout() {
  const { translate } = useLocales();

  const routes = [
    {
      id: 'documentation',
      title: translate('navigation.OPERATOR_DOCUMENTATION'),
      link: PATH_DASHBOARD.documentation,
      icon: <SetupIcon />,
    },
    {
      id: 'numbering',
      title: translate('navigation.NUMBERING_PROCESSING'),
      link: PATH_DASHBOARD.numbering.root,
      icon: <FileIcon />,
    },
    {
      id: 'preferences',
      title: translate('navigation.MODULE_PREFERENCES'),
      link: PATH_DASHBOARD.preferences,
      icon: <SetupIcon />,
    },
  ];

  return (
    <Layout>
      <AppHeader />

      <NavigationHeader routes={routes} />

      <MainStyle>
        <Outlet />
      </MainStyle>
    </Layout>
  );
}
