import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';

import { PATH_DASHBOARD } from './routes/paths';

export const DEFAULT_NUMBERING_TOOL_PATH = PATH_DASHBOARD.numbering.converter;

export const HEADER = {
  MAIN_DESKTOP_HEIGHT: 58,
};

export const defaultSettings = {
  themeMode: 'light',
};

export const allLangs = [
  {
    label: 'Ukrainian',
    value: 'uk',
    systemValue: ukUA,
    icon: '/assets/icons/flags/ic_flag_uk.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
];

export const defaultLang = allLangs[1];
