import { useLocation } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router';

import styled from 'styled-components';

import { Space } from '@bsg-core/components';
import { Card, Layout, Typography } from 'antd';

import useLocales from '../../hooks/useLocales';

import { PATH_DASHBOARD } from '../../routes/paths';

const { Sider, Content } = Layout;

// ----------------------------------------------------------------------

const MainStyle = styled(Layout)(() => ({
  width: '100%',
  height: '100%',
}));

const SiderContainer = styled(Sider)(({ theme }) => ({
  backgroundColor: `${theme.colorBgLayout}!important`,
}));

const ContentContainer = styled(Content)(() => ({
  marginLeft: '16px',
  backgroundColor: 'white',
}));

const Title = styled(Typography.Title)(({ theme }) => ({
  color: theme.colorSecondaryTitle,
  fontWeight: `500!important`,
}));

const Subtitle = styled(Typography.Text)(({ theme }) => ({
  color: theme.colorTextSecondaryLight,
}));

const WizardCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'white',
  cursor: 'pointer',
  h5: {
    fontWeight: `500!important`,
    color: theme.colorSuccessConfirm,
  },
  span: {
    color: theme.colorTextSecondaryNeutral,
    fontSize: '12px',
  },
}));

const ToolCard = styled(Card)(({ theme, active }) => ({
  backgroundColor: 'white',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: 500,
  color: theme.colorTextSecondaryLight,
  border: active ? `1px solid ${theme.colorNeutralBorder}` : '1px solid rgba(0, 0, 0, 0)',
  boxShadow: active ? '0px 2px 8px 0px #00000026' : null,
}));

// ----------------------------------------------------------------------

export default function NumberingLayout() {
  const { translate } = useLocales();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const pathConverter = PATH_DASHBOARD.numbering.converter;
  const pathOptimizer = PATH_DASHBOARD.numbering.optimizer;
  const pathGenerator = PATH_DASHBOARD.numbering.generator;

  const isActive = (path) => path === pathname;

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={24}>
      <Title level={5}>{translate('navigation.NUMBERING_PROCESSING')}</Title>

      <MainStyle direction="vertical" size={24}>
        <SiderContainer width={360}>
          <Space direction="vertical" size={12}>
            <WizardCard>
              <Typography.Title level={5}>{translate('navigation.NUMBERING_WIZARD')}</Typography.Title>
              <Typography.Text>{translate('navigation.WIZARD_DESCRIPTION')}</Typography.Text>
            </WizardCard>

            <Subtitle>{translate('navigation.STANDALONE_TOOLS')}</Subtitle>

            <ToolCard onClick={() => navigate(pathConverter)} active={isActive(pathConverter)}>
              {translate('navigation.RANGE_PREFIX')}
            </ToolCard>
            <ToolCard onClick={() => navigate(pathOptimizer)} active={isActive(pathOptimizer)}>
              {translate('navigation.PREFIX_OPTIMIZER')}
            </ToolCard>
            <ToolCard onClick={() => navigate(pathGenerator)} active={isActive(pathGenerator)}>
              {translate('navigation.BROKEN_PREFIX_GENERATOR')}
            </ToolCard>
          </Space>
        </SiderContainer>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </MainStyle>
    </Space>
  );
}

// <Title level={5}>{translate('navigation.NUMBERING_PROCESSING')}</Title>
//
// <Row gutter={16}>
//   <Col flex="360px">
//
//   </Col>
//   <Col flex="auto">
//     <ContentContainer>
//       <Outlet />
//     </ContentContainer>
//   </Col>
// </Row>
