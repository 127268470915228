import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './global.css';
import './theme/global-overrides.css';

import './locales/i18n';

import { HelmetProvider } from 'react-helmet-async';
import packageJson from '../package.json';
import reportWebVitals from './reportWebVitals';

import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';

console.log(`Current version is: ${packageJson.version}`);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <SettingsProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SettingsProvider>
  </HelmetProvider>
);

reportWebVitals(console.log);
