/* eslint-disable class-methods-use-this  */
/* eslint-disable consistent-return  */
class Storage {
  get(name) {
    if (typeof window === 'undefined') return null;

    return localStorage.getItem(name);
  }

  put(name, value) {
    if (typeof window === 'undefined') return null;

    localStorage.setItem(name, value);
  }

  getParsed(name) {
    if (typeof window === 'undefined') return null;

    return JSON.parse(localStorage.getItem(name));
  }

  putParsed(name, value) {
    if (typeof window === 'undefined') return null;

    localStorage.setItem(name, JSON.stringify(value));
  }

  remove(name) {
    if (typeof window === 'undefined') return null;

    localStorage.removeItem(name);
  }
}

export default new Storage();
