import alpha from 'color-alpha';

const PRIMARY = {
  light: {
    colorPrimary: '#1890FF',
    colorPrimaryBg: '#e6f7ff',
    colorBgHeader: '#345EA3',
    colorPrimaryBgHover: '#bae7ff',
    colorPrimaryBorder: '#91d5ff',
    colorPrimaryBorderHover: '#69c0ff',
    colorPrimaryHover: '#40a9ff',
    colorPrimaryActive: '#096dd9',
    colorPrimaryTextHover: '#40a9ff',
    colorPrimaryText: '#1890ff',
    colorPrimaryTextActive: '#096dd9',
    colorPrimaryTextSemiTransparent: '#85A5FF',
  },
  dark: {
    colorPrimary: '#588adc',
  },
};

const SUCCESS = {
  colorSuccessBg: '#e1e8da',
  colorSuccessBgHover: '#d4dbce',
  colorSuccessBorder: '#bccfae',
  colorSuccessBorderHover: '#9dc284',
  colorSuccessHover: '#9dc284',
  colorSuccess: '#61a83d',
  colorSuccessActive: '#438228',
  colorSuccessTextHover: '#7eb55e',
  colorSuccessText: '#61a83d',
  colorSuccessTextActive: '#438228',
  colorSuccessConfirm: '#52C41A',
  colorSuccessStroke: '#73D13D',
  colorSuccessTextSemiTransparent: '#95DE64',
};

const WARNING = {
  colorWarningBg: '#FAAD14',
  colorWarningStatusQueued: '#FFD666',
  colorWarningSecondaryBg: '#FEFFE6',
};

const ERROR = {
  colorError: '#e76563',
  colorErrorSecondaryBg: '#FFF1F0',
  colorErrorCancel: '#F5222D',
  colorErrorStatus: '#FF4D4F',
  colorErrorNotDelivered: '#FF85C0',
  colorErrorComplaint: '#FF7875',
  colorErrorStatusDark: '#CF1322',
};

const INFO = {
  colorInfo: '#448ef7',
  colorInfoTextActive: '#5971A2',
  colorInfoStatusDelivered: '#5CDBD3',
};

const NEUTRAL = {
  light: {
    colorText: alpha('#000000', 0.88),
    colorTextSecondary: alpha('#000000', 0.65),
    colorTextSecondaryDark: '#262626',
    colorTextSecondaryLighter: '#8C8C8C',
    colorTextSecondaryLight: '#535C6F',
    colorTextDisabled: '#C8CBD1',
    colorBorder: '#d9d9d9',
    colorBorderSecondary: '#F1F1F1',
    colorTableHead: '#FAFAFA',
    colorFill: alpha('#000000', 0.15),
    colorFillSecondary: alpha('#000000', 0.06),
    colorBgContainer: '#ffffff',
    colorBgElevated: '#ffffff',
    colorBgLayout: '#f5f5f5',
    colorBgSpotlight: alpha('#000000', 0.85),
    colorBgMask: alpha('#000000', 0.45),
    colorBorderNeutral: '#F0F0F0',
    colorTextSecondaryNeutral: '#9197A3',
    colorSecondaryTitle: '#414141',
    colorNeutralBorder: '#BFBFBF',
  },
  dark: {
    colorBorder: '#30568a',
    colorBorderSecondary: '#274670',
    colorFill: alpha('#ffffff', 0.18),
    colorFillSecondary: alpha('#ffffff', 0.12),
    colorFillTertiary: alpha('#ffffff', 0.08),
    colorFillQuaternary: alpha('#ffffff', 0.04),
    colorBgContainer: '#192c46',
    colorBgElevated: '#1e3555',
    colorBgLayout: '#0e1928',
    colorBgSpotlight: '#30568a',
    colorBgMask: alpha('#000000', 0.45),
  },
};

const palette = {
  light: {
    ...PRIMARY.light,
    ...SUCCESS,
    ...WARNING,
    ...ERROR,
    ...INFO,
    ...NEUTRAL.light,
  },
  dark: {
    ...PRIMARY.dark,
    ...SUCCESS,
    ...WARNING,
    ...ERROR,
    ...INFO,
    ...NEUTRAL.dark,
  },
};

export default palette;
