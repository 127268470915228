import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';

import useLocales from '../../../hooks/useLocales';

import Logo from '../../../components/Logo';

import { HEADER } from '../../../config';

// ----------------------------------------------------------------------

export default function AppHeader() {
  const { translate } = useLocales();

  const items = [];

  return (
    <Header
      style={{
        height: HEADER.MAIN_DESKTOP_HEIGHT,
        padding: '0 16px',
      }}
    >
      <Space size="large" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Logo />

        <Dropdown menu={{ items }} trigger={['click']}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <span style={{ color: 'white' }}>{translate('navigation.MONITORING')}</span>
              <DownOutlined style={{ color: 'white' }} />
            </Space>
          </a>
        </Dropdown>

        <Dropdown menu={{ items }} trigger={['click']}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <span style={{ color: 'white' }}>{translate('navigation.ADMINISTRATION')}</span>
              <DownOutlined style={{ color: 'white' }} />
            </Space>
          </a>
        </Dropdown>

        <Dropdown menu={{ items }} trigger={['click']}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <span style={{ color: 'white' }}>{translate('navigation.FINANCES')}</span>
              <DownOutlined style={{ color: 'white' }} />
            </Space>
          </a>
        </Dropdown>

        <Dropdown menu={{ items }} trigger={['click']}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <span style={{ color: 'white' }}>{translate('navigation.MODULES_NUMBERING')}</span>
              <DownOutlined style={{ color: 'white' }} />
            </Space>
          </a>
        </Dropdown>
      </Space>
    </Header>
  );
}
