import PropTypes from 'prop-types';

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { Space, Tabs } from '@bsg-core/components';

// ----------------------------------------------------------------------

const RootStyle = styled(Space)(({ theme, $singleRoute }) => ({
  width: '100%',
  paddingLeft: '15px',
  backgroundColor: 'white',
  '& .icon-container': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .ui4-tabs-tab.ui4-tabs-tab-active > div > a > div': $singleRoute
    ? {
        color: `${theme.colorTextSecondaryLight}!important`,
      }
    : {},
  '& .ui4-tabs-nav-more': {
    display: 'none',
  },
  '& .ui4-tabs-tab.ui4-tabs-tab-active path': {
    fill: `${theme.colorPrimary}!important`,
  },
  '& .ui4-tabs-tab a:hover path': {
    fill: `${theme.colorPrimary}!important`,
  },
}));

// ----------------------------------------------------------------------

NavigationHeader.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default function NavigationHeader({ routes }) {
  const location = useLocation();

  const currentRoute = useMemo(() => {
    const route = routes.find(
      (r) => r.link === location.pathname || (r.id !== 'dashboard' && location.pathname.includes(r.link))
    );

    return route?.id;
  }, [location.pathname, routes]);

  return (
    <RootStyle $singleRoute={routes.length === 1}>
      <Tabs
        tabs={routes.map((r) => ({
          ...r,
          title: r.icon ? (
            <span className="icon-container">
              {r.icon}&nbsp; {r.title}
            </span>
          ) : (
            r.title
          ),
        }))}
        activeTabId={currentRoute}
        style={{ width: '100%' }}
      />
    </RootStyle>
  );
}
