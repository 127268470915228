// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  documentation: path(ROOTS_DASHBOARD, '/documentation'),
  numbering: {
    root: path(ROOTS_DASHBOARD, '/numbering'),
    converter: path(ROOTS_DASHBOARD, '/numbering/range-prefix-converter'),
    optimizer: path(ROOTS_DASHBOARD, '/numbering/prefix-optimizer'),
    generator: path(ROOTS_DASHBOARD, '/numbering/broken-prefix-generator'),
  },
  preferences: path(ROOTS_DASHBOARD, '/preferences'),
};
