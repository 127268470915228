const en = {
  common: {
    SAVE: 'Save',
  },
  navigation: {
    ADMINISTRATION: 'Administration',
    FINANCES: 'Finances',
    MODULES_NUMBERING: 'Modules: Numbering Tool',
    MONITORING: 'Monitoring',
    OPERATOR_DOCUMENTATION: 'Operator documentation',
    NUMBERING_PROCESSING: 'Numbering processing',
    MODULE_PREFERENCES: 'Module preferences',
    NUMBERING_WIZARD: 'Numbering Wizard',
    WIZARD_DESCRIPTION:
      'This tool will guide you through all the standalone tools starting with the operator ranges and ending up with both prefixes and broken prefixes being ready for use',
    STANDALONE_TOOLS: 'Standalone tools',
    RANGE_PREFIX: 'Range-Prefix Converter',
    PREFIX_OPTIMIZER: 'Prefix Optimizer',
    BROKEN_PREFIX_GENERATOR: 'Broken Prefix Generator',
  },
  pages: {
    PAGE_NOT_FOUND: 'Page not found...',
    LOOKS_LIKE: "It looks like we couldn't find the page you were looking for. You can return to the",
    DASHBOARD: 'dashboard',
  },
  numbering: {
    UPLOAD_NON_OPTIMIZED: 'Please, upload a file with non-optimized operator prefixes for a single country',
    FILE_WILL_BE_VALIDATED: 'Note, that your file will be validated for conflicts before its optimization',
    FILE_FORMAT_STRUCTURE: 'Format & structure',
    FILE_FORMAT: '.xls or .xlsx with two columns, one with prefixes and the other with corresponding operator names',
    PREFIXES: 'Prefixes',
    OPERATOR: 'Operator',
    UPLOAD_FILE: 'Upload file',
    PROCESSING_COUNTRY: 'Processing Country Selection',
    COUNTRY_AUTO_SELECTION: '{auto check country}',
    SKIP_NON_RELEVANT: 'Skip numbering non-relevant to the processed country',
    INCLUDE_NON_RELEVANT: 'Include non-relevant numbering to the conflict list',
    START: 'Start',
    RESOLVE: 'Resolve',
    RETRY: 'Retry',
    CLOSE: 'Close',
    TOTAL_RECORDS: 'Total Records',
    PROCESSED: 'Processed',
    TIME_ELAPSED: 'Time Elapsed',
    SUCCESSFUL: 'Successful',
    CONFLICTS: 'Conflicts',
    SKIPPED: 'Skipped',
    VALIDATION_ERROR:
      'Oops... Something went wrong and your file was not validated Please, press the button below to give it another try or close this task',
    VALIDATION_CONFLICTS:
      'Warning! {conflicts} conflicts were detected in your file Please, press the button below to resolve them manually',
    VALIDATION_PENDING: 'Please, wait. Your ranges are now being validated...',
    RANGE_START: 'Range start',
    RANGE_STOP: 'Range stop',
    OPERATOR_NAME: 'Operator name',
    CONFLICT_TYPE: 'Conflict type',
    DOWNLOAD_NON_OPTIMIZED: 'Download Non-optimized File',
    FILE_NOT_OPTIMIZED: 'File not optimized! For optimization run ',
    PREFIX_OPTIMIZER: 'Prefix Optimizer',
    UPLOAD_RANGES:
      'Please, upload a file with operator ranges for a single country Note, that your file will be validated for conflicts before its conversion ',
    UPLOAD_PREFIXES:
      'Please, upload a file with non-optimized operator prefixes for a single country Note, that your file will be validated for conflicts before its optimization',
    NO_RANGE: 'No range',
    NO_PREFIX: 'No prefix',
    NO_OPERATOR: 'No operator',
    INVALID_RANGE: 'Invalid (absurd) range',
    NON_RELEVANT_NUMBERING: 'Non-relevant numbering',
    OVERLAP_SINGLE_OPERATOR: 'Overlap (single operator)',
    OVERLAP_DIFFERENT_OPERATOR: 'Overlap (different operator)',
    ACTIONS: 'Actions',
    SELECTED: 'Selected',
    APPLY: 'Apply',
    VALIDATE_AGAIN: 'Validate again',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    MASSACTION: 'massaction',
    REVALIDATION_REQUIRED: 'Revalidation required',
    NO_CONFLICTS_WERE_DETECTED:
      'No conflicts were detected in your file Please, press the button below to start the optimized prefixes file generation',
    GENERATE: 'Generate',
    PREFIX: 'Prefix',
    TOTAL: 'Total Records',
    OPTIMIZED_RECORDS: 'Optimized Records',
    OPTIMIZATION_PENDING: 'Please, wait. Your optimized prefixes file is now being generated...',
    YOUR_OPTIMIZED_PREFIXES:
      'Your optimized prefixes file with {prefixes} records was successfully generated\n' +
      'Please, press the button below to download it',
    OPTIMIZATION_ERROR:
      'Oops... Something went wrong and your optimized prefixes file was not generated \n' +
      'Please, press the button below to give it another try or close this task',
    DOWNLOAD: 'Download',
    UPLOADED_FILE: 'Uploaded file',
    YOUR_BROKEN_PREFIXES:
      'Your broken prefixes file with {broken} records was successfully generated \n' +
      'Please, press the button below to download it',
    BROKEN: 'Broken Prefixes',
    INVALID_FILE: "You've uploaded a file in invalid format. \n Please, upload .xls/.xlsx file with correct structure",
  },
  documentation: {
    COUNTRY: 'Country',
    SELECT_COUNTRY: 'Select country',
    OPERATOR: 'Operator',
    SELECT_OPERATOR: 'Select operator',
    REGION: 'Commercial Region',
    SELECT_REGION: 'Select Region',
    GSMA: 'GSMA Region',
    SELECT_GSMA: 'Select GSMA Region',
    VISIBILITY: 'Visibility',
    VISIBLE: 'Visibly',
    OPERATOR_AVAILABILITY: 'Operator Availability',
    SELECT_AVAILABILITY: 'Select Availability',
    SEARCH: 'Search',
    RESET: 'Reset',
    FILES: 'Files',
    FOLDERS: 'Folders',
    DOCUMENTS: 'Documents',
    IMAGES: 'Images',
    PROFILE: 'Profile',
    CATALOGS: 'Catalogs',
    EDIT_DATA: 'Edit data',
    OK: 'Ok',
  },
  preferences: {
    COMMON_SETTINGS: 'Common settings',
    MONTHLY_REPORT: 'Monthly Numbering Tool Report',
    SELECT_REPORT_PARAMETERS: 'Select Report Parameters',
    ENTER_EMAIL_ADDRESS:
      'Enter the email address where you would like to receive monthly log file. Specify which details of activity in the module have to be included into the file',
    folderCreated: 'Folder created',
    folderDeleted: 'Folder deleted',
    folderDownloaded: 'Folder downloaded',
    fileUploaded: 'File uploaded',
    attributesChanged: 'Attributes changed',
    regulatorInfoChanged: 'Regulator info changed',
    numberingWizardUsage: 'Numbering wizard usage',
    rangePrefixConverterUsage: 'Range prefix converter usage',
    optimizerUsage: 'Optimizer usage',
    brokenPrefixGeneratorUsage: 'Broken prefix generator usage',
  },
};

export default en;
