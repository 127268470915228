import PropTypes from 'prop-types';

import AppLayout from './app';
import NumberingLayout from './app/numbering';

Layout.propTypes = {
  variant: PropTypes.oneOf(['dashboard', 'numbering']),
};

// ----------------------------------------------------------------------

export default function Layout({ variant }) {
  if (variant === 'dashboard') {
    return <AppLayout />;
  }

  if (variant === 'numbering') {
    return <NumberingLayout />;
  }

  return <AppLayout />;
}
