import React from 'react';

import 'antd/dist/reset.css';

import { ConfigProvider, App as Application } from 'antd';

import { Provider } from 'react-redux';

import Router from './routes';
import ThemeProvider from './theme';
import useLocales from './hooks/useLocales';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

import { SocketProvider } from './contexts/SocketContext';

import store from './redux/store';

export default function App() {
  const { currentLang } = useLocales();

  return (
    <Provider store={store}>
      <MotionLazyContainer>
        <ConfigProvider locale={currentLang.systemValue}>
          <SocketProvider>
            <Application>
              <ThemeProvider>
                <ScrollToTop />
                <Router />
              </ThemeProvider>
            </Application>
          </SocketProvider>
        </ConfigProvider>
      </MotionLazyContainer>
    </Provider>
  );
}
