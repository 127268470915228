import Conflicts from '../../../api/gateways/http/Conflicts';

export const GET_CONFLICTS = 'GET_CONFLICTS';
export const GET_CONFLICTS_PENDING = 'GET_CONFLICTS_PENDING';
export const GET_CONFLICTS_REJECTED = 'GET_CONFLICTS_REJECTED';
export const GET_CONFLICTS_FULFILLED = 'GET_CONFLICTS_FULFILLED';

export const RESOLVE_CONFLICT = 'RESOLVE_CONFLICT';
export const RESOLVE_CONFLICT_PENDING = 'RESOLVE_CONFLICT_PENDING';
export const RESOLVE_CONFLICT_REJECTED = 'RESOLVE_CONFLICT_REJECTED';
export const RESOLVE_CONFLICT_FULFILLED = 'RESOLVE_CONFLICT_FULFILLED';

export const REMOVE_CONFLICT = 'REMOVE_CONFLICT';
export const REMOVE_CONFLICT_PENDING = 'REMOVE_CONFLICT_PENDING';
export const REMOVE_CONFLICT_REJECTED = 'REMOVE_CONFLICT_REJECTED';
export const REMOVE_CONFLICT_FULFILLED = 'REMOVE_CONFLICT_FULFILLED';

export const BULK_REMOVE_CONFLICTS = 'BULK_REMOVE_CONFLICTS';
export const BULK_REMOVE_CONFLICTS_PENDING = 'BULK_REMOVE_CONFLICTS_PENDING';
export const BULK_REMOVE_CONFLICTS_REJECTED = 'BULK_REMOVE_CONFLICTS_REJECTED';
export const BULK_REMOVE_CONFLICTS_FULFILLED = 'BULK_REMOVE_CONFLICTS_FULFILLED';

const getConflicts = ({ clientId, page, perPage }) => ({
  type: GET_CONFLICTS,
  payload: Conflicts.getConflicts({ clientId, page, perPage }),
});

const resolveConflict = ({ clientId, conflictId, override }) => ({
  type: RESOLVE_CONFLICT,
  payload: Conflicts.resolveConflict({ clientId, conflictId, override }),
});

const removeConflict = ({ clientId, conflictId }) => ({
  type: REMOVE_CONFLICT,
  payload: Conflicts.removeConflict({ clientId, conflictId }),
});

const bulkRemoveConflicts = ({ clientId, conflictsIds }) => ({
  type: BULK_REMOVE_CONFLICTS,
  payload: Conflicts.bulkRemoveConflicts({ clientId, conflictsIds }),
});

const action = {
  getConflicts,
  removeConflict,
  resolveConflict,
  bulkRemoveConflicts,
};

export default action;
