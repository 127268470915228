import PropTypes from 'prop-types';

import { createContext, useEffect, useState } from 'react';

import { socket } from '../services/Socket';

const initialState = {
  socket: null,
  clientId: null,
  isConnected: false,
};

const SocketContext = createContext(initialState);

// ----------------------------------------------------------------------

SocketProvider.propTypes = {
  children: PropTypes.node,
};

function SocketProvider({ children }) {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);

      console.log('Socket connection established');
    });
    socket.on('disconnect', () => {
      setIsConnected(false);

      console.log('Socket disconnected');
    });
    socket.on('connect_error', (err) => {
      console.log('Socket connection error', err);
    });
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        clientId: socket.id,
        isConnected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { SocketProvider, SocketContext };
