import { combineReducers } from 'redux';

import conflicts from './conflicts/reducer';
import numbering from './numbering/reducer';

const reducers = combineReducers({
  conflicts,
  numbering,
});

export default reducers;
