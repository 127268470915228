import axios from 'axios';

import Storage from './Storage';

class Http {
  constructor() {
    const instance = axios.create({
      baseURL: 'https://api.numbering-tool.bsg.world/',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        error.data = error.response.data;

        return Promise.reject(error);
      }
    );

    this.instance = instance;
  }

  setAuthHeader() {
    const accessToken = Storage.get('accessToken');

    if (accessToken) {
      const { headers } = this.instance.defaults;
      headers['X-API-Key'] = accessToken;
    }
  }

  setFormDataHeader() {
    const { headers } = this.instance.defaults;
    headers['Content-Type'] = 'multipart/form-data';
  }

  setJsonHeader() {
    const { headers } = this.instance.defaults;
    headers['Content-Type'] = 'application/json';
  }

  removeAuthHeader() {
    delete this.instance.defaults.headers['X-API-Key'];
  }
}

export default new Http();
