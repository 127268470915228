import { useToken } from 'antd/es/theme/internal';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

StyledProvider.propTypes = {
  children: PropTypes.node,
};

export default function StyledProvider({ children }) {
  const [, token] = useToken();

  return <ThemeProvider theme={token}>{children}</ThemeProvider>;
}
