import {
  GET_CONFLICTS_PENDING,
  GET_CONFLICTS_REJECTED,
  GET_CONFLICTS_FULFILLED,
  RESOLVE_CONFLICT_PENDING,
  RESOLVE_CONFLICT_REJECTED,
  RESOLVE_CONFLICT_FULFILLED,
  REMOVE_CONFLICT_PENDING,
  REMOVE_CONFLICT_REJECTED,
  REMOVE_CONFLICT_FULFILLED,
  BULK_REMOVE_CONFLICTS_PENDING,
  BULK_REMOVE_CONFLICTS_REJECTED,
  BULK_REMOVE_CONFLICTS_FULFILLED,
} from './action';

const initialState = {
  conflictsLoading: false,
  conflictsError: null,
  conflicts: [],
  conflictsTotal: [],
  resolutions: [],
};

// eslint-disable-next-line default-param-last
const conflictsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONFLICTS_PENDING:
    case RESOLVE_CONFLICT_PENDING:
    case REMOVE_CONFLICT_PENDING:
    case BULK_REMOVE_CONFLICTS_PENDING:
      return {
        ...state,
        conflictsLoading: true,
        conflictsError: null,
      };
    case GET_CONFLICTS_REJECTED:
    case RESOLVE_CONFLICT_REJECTED:
    case REMOVE_CONFLICT_REJECTED:
    case BULK_REMOVE_CONFLICTS_REJECTED:
      return {
        ...state,
        conflictsLoading: false,
        conflictsError: payload,
      };
    case GET_CONFLICTS_FULFILLED:
      return {
        ...state,
        conflictsLoading: false,
        conflictsError: null,
        conflicts: payload.list,
        conflictsTotal: payload.total,
        resolutions: [],
      };
    case RESOLVE_CONFLICT_FULFILLED:
      return {
        ...state,
        conflictsLoading: false,
        conflictsError: null,
        resolutions: [...state.resolutions.filter((r) => r.conflictId !== payload.conflictId), payload],
      };
    case REMOVE_CONFLICT_FULFILLED:
      return {
        ...state,
        conflictsLoading: false,
        conflictsError: null,
        conflicts: state.conflicts.filter((c) => c.id !== payload.id),
      };
    case BULK_REMOVE_CONFLICTS_FULFILLED:
      return {
        ...state,
        conflictsLoading: false,
        conflictsError: null,
        conflicts: state.conflicts.filter((c) => !payload.find((r) => r.id === c.id)),
      };
    default:
      return { ...state };
  }
};

export default conflictsReducer;
