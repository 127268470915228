import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import styled from 'styled-components';
import alpha from 'color-alpha';

import { Space } from 'antd';
import { useToken } from 'antd/es/theme/internal';
import Logo from './Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#345ea3',
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
  const [, token] = useToken();

  console.log('token', token);

  return (
    <>
      {!isDashboard && (
        <RootStyle {...other}>
          <m.div
            animate={{
              scale: [1, 0.9, 0.9, 1, 1],
              opacity: [1, 0.48, 0.48, 1, 1],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 0.5,
              repeat: Infinity,
            }}
          >
            <Logo disabledLink sx={{ width: 64, height: 64 }} />
          </m.div>

          <Space
            component={m.div}
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            style={{
              width: 100,
              height: 100,
              borderRadius: '25%',
              position: 'absolute',
              border: () => `solid 3px ${alpha(token.colorPrimary, 0.24)}`,
            }}
          />

          <Space
            component={m.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            style={{
              width: 120,
              height: 120,
              borderRadius: '25%',
              position: 'absolute',
              border: () => `solid 8px ${alpha(token.colorPrimary, 0.24)}`,
            }}
          />
        </RootStyle>
      )}
    </>
  );
}
