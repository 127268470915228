import {
  GET_COUNTRY_CODES_PENDING,
  GET_COUNTRY_CODES_REJECTED,
  GET_COUNTRY_CODES_FULFILLED,
  GET_NUMBERING_COUNTRY_PENDING,
  GET_NUMBERING_COUNTRY_REJECTED,
  GET_NUMBERING_COUNTRY_FULFILLED,
} from './action';

const initialState = {
  countryCodesLoading: false,
  countryCodesError: null,
  countryCodes: [],
  countryLoading: false,
  countryError: null,
  numberingCountry: null,
};

// eslint-disable-next-line default-param-last
const numberingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_COUNTRY_CODES_PENDING:
      return {
        ...state,
        countryCodesLoading: true,
        countryCodes: [],
      };
    case GET_NUMBERING_COUNTRY_PENDING:
      return {
        ...state,
        countryLoading: true,
        numberingCountry: null,
      };
    case GET_COUNTRY_CODES_REJECTED:
      return {
        ...state,
        countryCodesLoading: false,
        countryCodesError: payload,
      };
    case GET_NUMBERING_COUNTRY_REJECTED:
      return {
        ...state,
        countryLoading: false,
        countryError: payload,
      };
    case GET_COUNTRY_CODES_FULFILLED:
      return {
        ...state,
        countryCodesLoading: false,
        countryCodesError: null,
        countryCodes: payload,
      };
    case GET_NUMBERING_COUNTRY_FULFILLED:
      return {
        ...state,
        countryCodesLoading: false,
        countryCodesError: null,
        numberingCountry: payload,
      };
    default:
      return { ...state };
  }
};

export default numberingReducer;
