export default function FileIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7149 6.63798V5.35226C16.7149 5.23441 16.6185 5.13798 16.5006 5.13798H6.21491C6.09706 5.13798 6.00063 5.23441 6.00063 5.35226V6.63798C6.00063 6.75583 6.09706 6.85226 6.21491 6.85226H16.5006C16.6185 6.85226 16.7149 6.75583 16.7149 6.63798ZM6.21491 8.99512C6.09706 8.99512 6.00063 9.09155 6.00063 9.2094V10.4951C6.00063 10.613 6.09706 10.7094 6.21491 10.7094H11.1435C11.2613 10.7094 11.3578 10.613 11.3578 10.4951V9.2094C11.3578 9.09155 11.2613 8.99512 11.1435 8.99512H6.21491ZM16.2863 12.1023C13.0908 12.1023 10.5006 14.6924 10.5006 17.888C10.5006 21.0835 13.0908 23.6737 16.2863 23.6737C19.4819 23.6737 22.0721 21.0835 22.0721 17.888C22.0721 14.6924 19.4819 12.1023 16.2863 12.1023ZM19.1658 20.7674C18.3971 21.5362 17.3738 21.9594 16.2863 21.9594C15.1988 21.9594 14.1756 21.5362 13.4069 20.7674C12.6381 19.9987 12.2149 18.9755 12.2149 17.888C12.2149 16.8005 12.6381 15.7773 13.4069 15.0085C14.1756 14.2398 15.1988 13.8165 16.2863 13.8165C17.3738 13.8165 18.3971 14.2398 19.1658 15.0085C19.9346 15.7773 20.3578 16.8005 20.3578 17.888C20.3578 18.9755 19.9346 19.9987 19.1658 20.7674ZM18.6703 15.8523H17.4837C17.414 15.8523 17.3497 15.8844 17.3096 15.9407L15.6087 18.2924L14.9899 17.438C14.9701 17.4104 14.9439 17.3879 14.9136 17.3726C14.8833 17.3572 14.8498 17.3493 14.8158 17.3496H13.6346C13.4605 17.3496 13.3587 17.5478 13.4605 17.6898L15.4372 20.4246C15.523 20.5424 15.6971 20.5424 15.7828 20.4246L18.8417 16.1924C18.9462 16.0505 18.8444 15.8523 18.6703 15.8523ZM10.0721 21.1023H3.85777V2.24512H18.8578V11.4594C18.8578 11.5773 18.9542 11.6737 19.0721 11.6737H20.5721C20.6899 11.6737 20.7863 11.5773 20.7863 11.4594V1.17369C20.7863 0.699583 20.4033 0.316547 19.9292 0.316547H2.78634C2.31223 0.316547 1.9292 0.699583 1.9292 1.17369V22.1737C1.9292 22.6478 2.31223 23.0308 2.78634 23.0308H10.0721C10.1899 23.0308 10.2863 22.9344 10.2863 22.8165V21.3165C10.2863 21.1987 10.1899 21.1023 10.0721 21.1023Z"
        fill="#535C6F"
      />
    </svg>
  );
}
