import React, { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router';

import Layout from '../layouts';
import LoadingScreen from '../components/LoadingScreen';

import { PATH_DASHBOARD, PATH_PAGE } from './paths';
import { DEFAULT_NUMBERING_TOOL_PATH } from '../config';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: PATH_DASHBOARD.root,
      element: <Layout variant="dashboard" />,
      children: [
        { element: <Navigate to="/dashboard/documentation" replace />, index: true },
        { path: PATH_DASHBOARD.documentation, element: <DocumentationPage /> },
        { path: PATH_DASHBOARD.preferences, element: <PreferencesPage /> },
        {
          path: PATH_DASHBOARD.numbering.root,
          element: <Layout variant="numbering" />,
          children: [
            { element: <Navigate to={DEFAULT_NUMBERING_TOOL_PATH} replace />, index: true },
            { path: PATH_DASHBOARD.numbering.optimizer, element: <OptimizerPage /> },
            { path: PATH_DASHBOARD.numbering.converter, element: <ConverterPage /> },
            { path: PATH_DASHBOARD.numbering.generator, element: <GeneratorPage /> },
          ],
        },
      ],
    },
    {
      path: PATH_PAGE.page404,
      element: <Page404 />,
    },
    {
      path: '',
      element: <Navigate to={PATH_DASHBOARD.root} replace />,
    },
    {
      path: '*',
      element: <Navigate to={PATH_PAGE.page404} replace />,
    },
  ]);
}

// Pages
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// Dashboard
const DocumentationPage = Loadable(lazy(() => import('../pages/dashboard/Documentation')));
const PreferencesPage = Loadable(lazy(() => import('../pages/dashboard/Preferences')));

// Numbering
const OptimizerPage = Loadable(lazy(() => import('../pages/dashboard/numbering/Optimizer')));
const GeneratorPage = Loadable(lazy(() => import('../pages/dashboard/numbering/Generator')));
const ConverterPage = Loadable(lazy(() => import('../pages/dashboard/numbering/Converter')));
