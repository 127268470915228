import Numbering from '../../../api/gateways/http/Numbering';

export const GET_COUNTRY_CODES = 'GET_COUNTRY_CODES';
export const GET_COUNTRY_CODES_PENDING = 'GET_COUNTRY_CODES_PENDING';
export const GET_COUNTRY_CODES_REJECTED = 'GET_COUNTRY_CODES_REJECTED';
export const GET_COUNTRY_CODES_FULFILLED = 'GET_COUNTRY_CODES_FULFILLED';

export const GET_NUMBERING_COUNTRY = 'GET_NUMBERING_COUNTRY';
export const GET_NUMBERING_COUNTRY_PENDING = 'GET_NUMBERING_COUNTRY_PENDING';
export const GET_NUMBERING_COUNTRY_REJECTED = 'GET_NUMBERING_COUNTRY_REJECTED';
export const GET_NUMBERING_COUNTRY_FULFILLED = 'GET_NUMBERING_COUNTRY_FULFILLED';

const getCountryCodes = () => ({
  type: GET_COUNTRY_CODES,
  payload: Numbering.getCountryCodes(),
});

const getNumberingCountry = (clientId) => ({
  type: GET_NUMBERING_COUNTRY,
  payload: Numbering.getNumberingCountry(clientId),
});

const actions = {
  getCountryCodes,
  getNumberingCountry,
};

export default actions;
